import { useEffect, useState } from 'react';
import Logo from './img/logo'
import Copy from './img/copyIcon'
import Button from './helpers/Button'
import Page404 from './helpers/Page404';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { CircularProgress, Progress, useToast } from '@chakra-ui/react'

export interface IDeposit {
  id: number
  pendingAmount: string
  walletAcceptor: string
  expirationTimestamp: number
  status: 'waiting' | 'success' | 'expired'
  payment_number: string
  payment_currency: string
  createdAt: string
  updatedAt: string
  deposit: object
}

function App() {
  const toast = useToast()
  const [isAdressCopied, setIsAdressCopied] = useState<boolean>(false);
  const [isAmountCopied, setIsAmountCopied] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false)
  const queryParameters = new URLSearchParams(window.location.search)
  const payment_number = queryParameters.get("payment_number")
  const [data, setData] = useState<IDeposit>()
  const [error, setError] = useState<boolean>(true)
  const fetchData = async () => {
    try {
      let res = await fetch('https://back.paypac.io/getDeposit', {
        method: "POST",
        body: JSON.stringify({
          token: '2buXKLFBx4YYgUGLK8uvGQFNkWtZtLeIZjmU20iJdDsrV0JEMYSNPS8heXiwMbsfKonhkqeuxcLKvdw2jwNbqvNrouIxYbiZIKqebS1GVy1AsWVtnh5886p4hYQXoGqK',
          payment_number: payment_number
        }),
        headers: {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      if (res.ok) {
        const responceData = await res.json();
        const depositData = responceData.deposit
        setData(depositData)
        setError(false)
        return responceData.status
      }
      else {
        throw new Error('Data error')
      }
    }
    catch (e) {
      setError(true)
      toast({
        title: `Error`,
        description: 'Deposit network is not available at the moment. Please try again later.',
        position: 'top-right',
        status: 'error',
        duration: 10000
      })
    }
  }
  useEffect(() => {
    fetchData()
    if (accepted) {
      toast({
        title: `In progress...`,
        status: 'loading',
        position: 'top-right',
        description: 'Status update is in progress, please wait...',
        duration: 10000
      })
      if (data != undefined) {
        if (data.status != 'success') {
          let interval = setInterval(async (): Promise<void> => {
            await fetchData()
            if (data != undefined) {
              console.log(data.status);
              if (data.status == 'success' || data.status == 'expired') {
                clearInterval(interval)
              }
            }
            else {
              clearInterval(interval)
              toast({
                title: `Error`,
                position: 'top-right',
                description: 'Error occurred while loading the data. Please contact support for help.',
                status: 'error',
                isClosable: true,
              })
            }
          }, 11000)
        }
      }
      else {
        toast({
          title: `Error`,
          position: 'top-right',
          description: 'Error occurred while loading the data. Please contact support for help.',
          status: 'error',
          isClosable: true,
        })
      }
    }

    // fetch('https://back.paypac.io/getDeposit', {
    //   method: "POST",
    //   body: JSON.stringify({
    //     token: '2buXKLFBx4YYgUGLK8uvGQFNkWtZtLeIZjmU20iJdDsrV0JEMYSNPS8heXiwMbsfKonhkqeuxcLKvdw2jwNbqvNrouIxYbiZIKqebS1GVy1AsWVtnh5886p4hYQXoGqK',
    //     payment_number: payment_number
    //   }),
    //   headers: {
    //     'Content-type': 'application/json',
    //     'Access-Control-Allow-Origin': '*'
    //   }
    // })
    //   .then(res => {
    //     console.log(res);
    //   })
    //   .catch(err => {
    //     console.log(err);

    //   })
  }, [accepted])

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyAdressClick = (text: string) => {
    console.log(data);

    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(text)
      .then(() => {
        // If successful, update the isCopied state value
        setIsAdressCopied(true);
        setTimeout(() => {
          setIsAdressCopied(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const handleCopyAmountClick = (text: string) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(text)
      .then(() => {
        // If successful, update the isCopied state value
        setIsAmountCopied(true);
        setTimeout(() => {
          setIsAmountCopied(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="bg-black w-full h-full flex items-center justify-center">
      {accepted ?
        data != undefined ?

          <div className="text-white bg-darkGray gap-4 px-6 py-5 lg:w-3/6 md:w-4/5 w-5/6 max-w-[522px] flex flex-col items-center rounded-2xl">
            <div className='w-full text-yellow'>
              <CircularProgress isIndeterminate color='yellow.200' thickness='5px' />
            </div>
            <Logo />
            <h1 className='lg:md:text-[32px] sm:text-2xl text-xl text-center font-orbitron'>PayPac</h1>
            <div className='w-full'>
              <span className='text-sm  whitespace-nowrap'>
                Address {data.payment_currency}
              </span>
              <div className='flex flex-row items-center bg-customBlack rounded-xl justify-between lg:p-3 md:p-2 sm:p-2 p-2  w-full text-sm text-start text-yellow font-bold'>
                <span className='text-lg relative font-normal overflow-hidden whitespace-nowrap'>
                  {data.walletAcceptor.substr(0, 6) + '....' + data.walletAcceptor.slice(-6)}
                </span>
                <div className='relative'>
                  <button className="px-2" onClick={() => handleCopyAdressClick(data.walletAcceptor)}>
                    <Copy fill={isAdressCopied ? "yellow" : 'white'} />
                  </button>
                  {isAdressCopied && <span className='text-sm absolute top-[-100%] right-1/2 transform translate-x-1/2 bg-yellow-500 p-1 rounded-xl'>Copied</span>}
                </div>
              </div>
            </div>
            <div className='w-full'>
              {/* <div className='flex flex-row justify-between p-1'> */}
              <span className='text-sm '>
                The exact amount sent to {data.payment_currency}
              </span>
              {/* {isAmountCopied && <span className='text-sm text-black relative bg-[yellow] p-1 rounded-xl'>copy</span>}
              </div> */}
              <div className='flex flex-row items-center bg-customBlack rounded-xl justify-between lg:p-3 md:p-2 sm:p-2 p-2  w-full text-sm text-start text-yellow font-bold'>
                <span className='text-lg font-normal'>
                  {data.pendingAmount}
                </span>
                <div className='relative'>
                  <button onClick={() => handleCopyAmountClick(data.pendingAmount)}>
                    <Copy fill={isAmountCopied ? "yellow" : 'white'} />
                  </button>
                  {isAmountCopied && <span className='text-sm absolute top-[-100%] right-1/2 transform translate-x-1/2 bg-yellow-500 p-1 rounded-xl'>Copied</span>}
                </div>
              </div>
            </div>
            <ul className='w-full flex flex-col gap-2'>
              <li className='w-full flex flex-row items-center justify-between'>
                <span className='text-base font-orbitron'>
                  Payment number
                </span>
                <span className='text-sm opacity-70 font-chakra'>
                  {data?.payment_number}
                </span>
              </li>
              <li className='w-full flex flex-row items-center justify-between'>
                <span className='text-base font-orbitron'>
                  PPID
                </span>
                <span className='text-sm opacity-70 font-chakra'>
                  {data.id}
                </span>
              </li>
              <li className='w-full flex flex-row items-center justify-between'>
                <span className='text-base font-orbitron'>
                  Commission
                </span>
                <span className='text-sm opacity-70 font-chakra'>
                  0%
                </span>
              </li>
              <li className='w-full flex flex-row items-center justify-between'>
                <span className='text-base font-orbitron'>
                  Date and time
                </span>
                <span className='text-sm opacity-70 font-chakra'>
                  {new Date(data.createdAt).toUTCString()}
                </span>
              </li>
              <li className='w-full flex flex-row items-center justify-between'>
                <span className='text-base font-orbitron '>
                  Status
                </span>
                <span className={`text-sm opacity-70 font-chakra ${data.status == 'waiting' && 'text-red-500'} ${data.status == 'success' && 'text-green-500'} ${data.status == 'expired' && 'text-yellow-500'} font-bold`}>
                  {data.status}
                </span>
              </li>
            </ul>

          </div>
          :
          <Page404 />
        :
        <div className="text-white bg-darkGray rounded-2xl lg:gap-4 md:gap-3 sm:gap-2 gap-2 p-3 md:p-8 w-[90%] md:w-[75%] lg:w-2/5 flex flex-col items-center">
          <h1 className='text-2xl text-center font-orbitron'>Attention!</h1>
          <div className='lg:p-3 md:p-2 sm:p-1 p-1  w-full lg:md:text-lg text-base text-center font-chakra'>
            1. You can pay your bill from any convenient wallet or exchange.
          </div>
          <div className='lg:p-3 md:p-2 sm:p-1 p-1  w-full lg:md:text-lg text-base text-center font-chakra'>
            2. Make sure you have enough {data?.payment_currency == 'OCTA' ? 'OCTA' : "BNB"} to pay the network fee.
          </div>
          <div className='lg:p-3 md:p-2 sm:p-1 p-1  w-full lg:md:text-lg text-base text-center font-chakra'>
            3. Send only the specified amount, otherwise the payment may not be credited automatically.
          </div>
          <div className='lg:p-3 md:p-2 sm:p-1 p-1  w-full lg:md:text-lg text-base text-center font-chakra'>
            4. Send only {data?.payment_currency}. If you send other coins or to another network, the funds will be lost.
          </div>
          <div className='lg:p-3 md:p-2 sm:p-1 p-1  w-full lg:md:text-lg text-base text-center font-chakra'>
            5. Please make sure your device and browser are secure to prevent unauthorised access or information leaks
          </div>
          <div className='lg:md:w-1/2 sm:w-2/3 w-full'>
            <Button disabled={error} content='Accept' type='button' buttonStyle='yellow' textColor='text-black' padding='p-3' rounded='rounded-xl' onClick={() => setAccepted(true)} />
          </div>

        </div>
      }
    </div >
  );
}
export default App;

