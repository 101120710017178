import { FunctionComponent } from "react";


const Logo: FunctionComponent = () => {
    return (<svg width="244" height="110" viewBox="0 0 244 110" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M116.995 84.5805V73.9016H125.759C126.081 73.9016 126.373 73.9857 126.636 74.1541C126.908 74.3125 127.123 74.5254 127.278 74.7927C127.434 75.0601 127.512 75.3571 127.512 75.6839V78.8178C127.512 79.1445 127.434 79.4416 127.278 79.7089C127.123 79.9763 126.908 80.1941 126.636 80.3624C126.373 80.5208 126.081 80.6001 125.759 80.6001H118.178V84.5805H116.995ZM118.748 79.3822H125.759C125.915 79.3822 126.046 79.3277 126.154 79.2188C126.27 79.1099 126.329 78.9762 126.329 78.8178V75.6839C126.329 75.5254 126.27 75.3918 126.154 75.2829C126.046 75.164 125.915 75.1046 125.759 75.1046H118.748C118.592 75.1046 118.455 75.164 118.339 75.2829C118.231 75.3918 118.178 75.5254 118.178 75.6839V78.8178C118.178 78.9762 118.231 79.1099 118.339 79.2188C118.455 79.3277 118.592 79.3822 118.748 79.3822Z" fill="#FFF100" />
        <path d="M142.251 84.5805L138.819 80.4218H140.367L143.814 84.5657V84.5805H142.251ZM133.37 84.5805V73.9016H142.134C142.456 73.9016 142.748 73.9857 143.011 74.1541C143.284 74.3125 143.498 74.5254 143.654 74.7927C143.809 75.0601 143.887 75.3571 143.887 75.6839V78.8178C143.887 79.1445 143.809 79.4416 143.654 79.7089C143.498 79.9763 143.284 80.1941 143.011 80.3624C142.748 80.5208 142.456 80.6001 142.134 80.6001H134.553V84.5805H133.37ZM135.123 79.3822H142.134C142.29 79.3822 142.422 79.3277 142.529 79.2188C142.646 79.1099 142.704 78.9762 142.704 78.8178V75.6839C142.704 75.5254 142.646 75.3918 142.529 75.2829C142.422 75.164 142.29 75.1046 142.134 75.1046H135.123C134.967 75.1046 134.831 75.164 134.714 75.2829C134.607 75.3918 134.553 75.5254 134.553 75.6839V78.8178C134.553 78.9762 134.607 79.1099 134.714 79.2188C134.831 79.3277 134.967 79.3822 135.123 79.3822Z" fill="#FFF100" />
        <path d="M151.968 84.5805C151.647 84.5805 151.35 84.5013 151.077 84.3429C150.814 84.1845 150.605 83.9716 150.449 83.7042C150.293 83.427 150.215 83.125 150.215 82.7982V75.669C150.215 75.3423 150.293 75.0452 150.449 74.7779C150.605 74.5006 150.814 74.2828 151.077 74.1244C151.35 73.9659 151.647 73.8867 151.968 73.8867H158.98C159.301 73.8867 159.593 73.9659 159.856 74.1244C160.129 74.2828 160.343 74.5006 160.499 74.7779C160.655 75.0452 160.733 75.3423 160.733 75.669V82.7982C160.733 83.125 160.655 83.427 160.499 83.7042C160.343 83.9716 160.129 84.1845 159.856 84.3429C159.593 84.5013 159.301 84.5805 158.98 84.5805H151.968ZM151.968 83.3775H158.98C159.136 83.3775 159.267 83.323 159.374 83.2141C159.491 83.0953 159.55 82.9567 159.55 82.7982V75.669C159.55 75.5106 159.491 75.3769 159.374 75.268C159.267 75.1492 159.136 75.0898 158.98 75.0898H151.968C151.812 75.0898 151.676 75.1492 151.559 75.268C151.452 75.3769 151.399 75.5106 151.399 75.669V82.7982C151.399 82.9567 151.452 83.0953 151.559 83.2141C151.676 83.323 151.812 83.3775 151.968 83.3775Z" fill="#FFF100" />
        <path d="M168.155 84.5805C167.834 84.5805 167.537 84.5013 167.264 84.3429C167.001 84.1845 166.792 83.9716 166.636 83.7042C166.48 83.427 166.402 83.125 166.402 82.7982V81.9516H167.586V82.7982C167.586 82.9567 167.639 83.0953 167.746 83.2141C167.863 83.323 167.999 83.3775 168.155 83.3775H175.167C175.323 83.3775 175.454 83.323 175.561 83.2141C175.678 83.0953 175.737 82.9567 175.737 82.7982V73.8867H176.92V82.7982C176.92 83.125 176.842 83.427 176.686 83.7042C176.53 83.9716 176.316 84.1845 176.043 84.3429C175.78 84.5013 175.488 84.5805 175.167 84.5805H168.155Z" fill="#FFF100" />
        <path d="M183.41 84.5805V73.8867H193.007V75.0898H184.593V78.6247H191.356V79.8426H184.593V83.3775H193.007V84.5805H183.41Z" fill="#FFF100" />
        <path d="M201.138 84.5805C200.816 84.5805 200.519 84.5013 200.247 84.3429C199.984 84.1845 199.774 83.9716 199.618 83.7042C199.463 83.427 199.385 83.125 199.385 82.7982V75.669C199.385 75.3423 199.463 75.0452 199.618 74.7779C199.774 74.5006 199.984 74.2828 200.247 74.1244C200.519 73.9659 200.816 73.8867 201.138 73.8867H209.873V75.0898H201.138C200.982 75.0898 200.846 75.1492 200.729 75.268C200.622 75.3769 200.568 75.5106 200.568 75.669V82.7982C200.568 82.9567 200.622 83.0953 200.729 83.2141C200.846 83.323 200.982 83.3775 201.138 83.3775H209.873V84.5805H201.138Z" fill="#FFF100" />
        <path d="M220.365 84.5805V75.0898H215.691V73.8867H226.208V75.0898H221.548V84.5805H220.365Z" fill="#FFF100" />
        <path d="M157.542 25.4219H162.111H179.58C184.032 25.4219 187.642 29.0446 187.642 33.5134V65.6943C187.642 65.9457 187.438 66.1494 187.187 66.1494H180.035C179.783 66.1494 179.58 65.9457 179.58 65.6943V56.1359C179.58 55.6333 179.172 55.2258 178.669 55.2258H161.946C161.443 55.2258 161.036 55.6333 161.036 56.1359V65.6943C161.036 65.9457 160.832 66.1494 160.581 66.1494H153.428C153.177 66.1494 152.973 65.9457 152.973 65.6943V55.2258C152.973 50.757 156.583 47.1342 161.036 47.1342H178.669C179.172 47.1342 179.58 46.7268 179.58 46.2241V34.4235C179.58 33.9209 179.172 33.5134 178.669 33.5134H157.808C157.644 33.5134 157.492 33.425 157.411 33.2821L152.973 25.4219H157.542Z" fill="white" />
        <path d="M221.639 25.4219H217.07H201.08C196.627 25.4219 193.017 29.0446 193.017 33.5134V58.0578C193.017 62.5267 196.627 66.1494 201.08 66.1494H225.753C226.004 66.1494 226.208 65.9457 226.208 65.6943V58.5129C226.208 58.2616 226.004 58.0578 225.753 58.0578H201.99C201.487 58.0578 201.08 57.6504 201.08 57.1477V34.4235C201.08 33.9209 201.487 33.5134 201.99 33.5134H221.373C221.538 33.5134 221.689 33.425 221.77 33.2821L226.094 25.6232C226.145 25.5333 226.08 25.4219 225.977 25.4219H221.639Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M121.126 25.4219H116.558H111.989L116.558 33.5134L116.558 65.6943C116.558 65.9457 116.761 66.1494 117.013 66.1494H124.165C124.416 66.1494 124.62 65.9457 124.62 65.6943V56.8441H141.686C146.139 56.8441 149.748 53.2214 149.748 48.7525V33.5134C149.748 29.0446 146.139 25.4219 141.686 25.4219H121.126ZM140.548 33.5134H125.758H124.62V34.6511V47.6149V48.7525H125.758H140.548C141.176 48.7525 141.686 48.2432 141.686 47.6149V34.6511C141.686 34.0228 141.176 33.5134 140.548 33.5134Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M61.4612 40.8138C62.8015 38.1887 63.4988 35.2656 63.4726 32.2878C63.4371 28.2377 62.0655 24.3124 59.5703 21.1201C57.0751 17.9277 53.5958 15.6466 49.6713 14.6302C45.7467 13.6137 41.5962 13.9187 37.8628 15.4978C34.1294 17.0769 31.0215 19.842 29.0207 23.3646C27.0199 26.8873 26.2378 30.9708 26.7958 34.9825C27.3537 38.9942 29.2205 42.7101 32.1068 45.5544C34.2289 47.6457 36.8152 49.18 39.6349 50.0455L41.3993 35.9487C41.9199 31.7887 46.7484 29.7464 50.1009 32.2683L61.4612 40.8138ZM63.5815 43.5519C62.9354 44.6284 61.4975 44.824 60.4939 44.069L48.1852 34.8099C46.7883 33.7592 44.7764 34.6101 44.5595 36.3435L42.6479 51.6172C42.492 52.8626 41.3507 53.7579 40.1273 53.4722C36.2668 52.5707 32.7134 50.6213 29.8702 47.8195C26.485 44.4836 24.2956 40.1254 23.6413 35.4204C22.9869 30.7153 23.9041 25.9259 26.2507 21.7944C28.5973 17.6629 32.2424 14.4199 36.6211 12.5679C40.9998 10.7158 45.8677 10.3582 50.4705 11.5503C55.0733 12.7425 59.1541 15.4179 62.0805 19.162C65.0069 22.906 66.6156 27.5098 66.6573 32.2599C66.6923 36.2495 65.6203 40.1552 63.5815 43.5519Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M65.8179 69.8957C67.4234 72.3679 69.6088 74.4327 72.2033 75.8989C75.732 77.8932 79.8206 78.6692 83.8356 78.1066C87.8505 77.5441 91.5677 75.6744 94.4111 72.7873C97.2545 69.9002 99.0654 66.1568 99.5632 62.1372C100.061 58.1176 99.2179 54.0462 97.1646 50.5539C95.1113 47.0615 91.9623 44.3431 88.2057 42.82C84.4491 41.2969 80.2944 41.054 76.3856 42.129C73.5116 42.9193 70.8884 44.3897 68.7282 46.3965L78.5003 53.787C82.5433 56.8446 81.7653 63.1263 77.0983 65.1073L65.8179 69.8957ZM62.9624 71.3395C61.9618 69.744 62.7921 67.7231 64.5266 66.9869L75.8528 62.1791C78.2668 61.1544 78.6692 57.9053 76.578 56.3237L66.7662 48.9032C65.2636 47.7669 64.9514 45.6047 66.311 44.301C68.8907 41.8274 72.0586 40.0189 75.5403 39.0614C80.1247 37.8006 84.9974 38.0855 89.4034 39.8719C93.8093 41.6583 97.5025 44.8465 99.9108 48.9425C102.319 53.0384 103.308 57.8135 102.724 62.5279C102.14 67.2422 100.016 71.6326 96.6813 75.0187C93.3465 78.4048 88.9868 80.5977 84.2779 81.2575C79.569 81.9173 74.7738 81.0072 70.6351 78.6682C67.492 76.8918 64.8608 74.3666 62.9624 71.3395Z" fill="#FFF100" />
        <path fillRule="evenodd" clipRule="evenodd" d="M57.3736 73.4799C58.0258 76.3539 57.9813 79.3585 57.2222 82.2383C56.1897 86.155 53.8931 89.6222 50.6881 92.1026C47.4831 94.5831 43.5486 95.9384 39.4944 95.9584C35.4402 95.9784 31.4926 94.6622 28.2632 92.2135C25.0337 89.7648 22.7028 86.3205 21.6316 82.4142C20.5604 78.5079 20.8087 74.3577 22.338 70.6067C23.8673 66.8557 26.5923 63.7132 30.0907 61.6662C32.6629 60.1612 35.5476 59.3102 38.4938 59.1647L36.7302 73.2616C36.2098 77.4217 40.3864 80.5885 44.2572 78.9688L57.3736 73.4799ZM60.1034 71.3475C59.7424 70.1453 58.397 69.6022 57.2381 70.0871L43.0267 76.0341C41.4139 76.7091 39.6736 75.3895 39.8905 73.6562L41.8013 58.3824C41.9571 57.1369 41.0715 55.9885 39.8154 55.9645C35.8516 55.889 31.9272 56.9044 28.4811 58.9208C24.378 61.3216 21.1821 65.0072 19.3885 69.4065C17.5948 73.8059 17.3036 78.6734 18.56 83.2549C19.8164 87.8363 22.5501 91.876 26.3377 94.7479C30.1253 97.6197 34.7553 99.1635 39.5102 99.14C44.2651 99.1165 48.8796 97.527 52.6385 94.6178C56.3975 91.7086 59.0911 87.6422 60.302 83.0485C61.319 79.1903 61.2424 75.1411 60.1034 71.3475Z" fill="white" />
        <path d="M71.8662 56.7243C73.2479 57.7811 72.9786 59.9334 71.379 60.6179L47.1112 71.0016C45.4964 71.6925 43.7403 70.372 43.9583 68.6306L47.2638 42.2147C47.4817 40.4733 49.5091 39.6253 50.9039 40.6921L71.8662 56.7243Z" fill="white" />
    </svg>


    );
}

export default Logo;

