import { FunctionComponent } from "react";

interface props {
    fill: string
}

const Copy: FunctionComponent<props> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 9H8C9.1046 9 10 8.10457 10 7V3M4 8.82843V17C4 18.1046 4.8954 19 6 19H14C15.1046 19 16 18.1046 16 17V5C16 3.89543 15.1046 3 14 3H9.8284C9.298 3 8.7893 3.21071 8.4142 3.58579L4.5858 7.41421C4.2107 7.78929 4 8.29799 4 8.82843Z" stroke="#FFF100" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M16 6H17C18.1046 6 19 6.89543 19 8V20C19 21.1046 18.1046 22 17 22H9C7.8954 22 7 21.1046 7 20V19" stroke="#FFF100" strokeWidth="1.25" strokeLinecap="round" />
        </svg>


    );
}

export default Copy;
